import downloadjs from "downloadjs";
import axios from './api/axios'

const SIGNED_DOC_DOWNLOAD_URL = 'signing/download/'

function downloadSignedPDF(docName, setLoading) {
  return function () {
    if(setLoading) {
      setLoading(true)
    }

    let config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("authToken")}`,
      },
      responseType: "blob",
    };

    let url = SIGNED_DOC_DOWNLOAD_URL + docName;
    axios
      .get(url, config)
      .then((response) => {
        downloadjs(response.data, docName + ".pdf", "application/pdf");
      })
      .catch((error) => {
        console.log(error);
        alert("Something went wrong on our end. Please contact us for help.");
      })
      .finally(() => {
        if(setLoading) {
          setLoading(false)
        }
      })
  };
}

export default downloadSignedPDF
