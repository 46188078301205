import axios from './api/axios'
const REQUEST_DEVICE_URL = 'user/requestdevice/'

export default function RequestDevice(offices, additional) {
  let config = {
    headers: {
      "Authorization": `JWT ${localStorage.getItem('authToken')}`
    }
  }

  let data = {
    'additional': additional,
    'offices' : offices
  }

  return axios.post(REQUEST_DEVICE_URL, data, config)
}