import React from 'react'
import { useState } from 'react'
import "./styles.css"
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OfficeForm from './OfficeForm';

const Demographics = (props) => {
    const [showShippingAddress, setShowShippingAddress] = useState([])
    const [salesRepEmailErr, setSalesRepEmailErr] = useState()
    const [emailErr, setEmailErr] = useState()
    const [hcpEmailErr, setHCPEmailErr] = useState()
    const [showHCPContent, setHCPContent] = useState(false);
    const [npiErr, setNpiErr] = useState('');

    function handleDemoField(field) {
        return function (e) {
            const targetValue = e.target.value
            props.onDemoChange(field, targetValue)
            if (targetValue === 'sharedAccount'){
                setHCPContent(true)                          
            }else if (targetValue === 'newAccount' || targetValue === 'existingAccount'){
                setHCPContent(false)
            }
        }
    }
    function handleNPIField(field) {
        return function (e) {
            const value = e.target.value
            const validNpiRE = /^[0-9\b]+$/            
            let msg = '';
            if (!value.trim()){
                msg = 'Please enter 10 digit value';
            }
            else if (!validNpiRE.test(value)){
                msg = "Please enter numeric only values.";
            }
            else if (value.length < 10){
                msg = "NPI# must be a 10 digits long";
            }

            setNpiErr(msg);
            props.onDemoChange(field, value)
            props.onValidationError(msg)
        }
    }
    
    function handleHCPField(field) {
        return function (e) {
            props.onDemoChange(field, e.target.value)
        }
    }
    function handleOfficeField(office, field, value) {
        props.onOfficeChange(office, field, value)
    }

    function handleShippingField(office, field, value) {
        props.onShippingChange(office, field, value)
    }

    function handleSameAsShipping(office, value) {
        props.onSameAsShippingChange(office, value)
    }

    function handleOnOfficeRemove(office) {
        props.onOfficeRemove(office)
    }

    function validateEmail(field) {
        return function (e) {
            const value = e.target.value
            const validEmailRE = field === 'salesRepEmail' ? /^[^\s]+@bms.com\s*$/ : new RegExp('.+@.+')
            let msg = field === 'salesRepEmail' ? "Please enter a valid @bms.com email address." : "Please enter a valid email address."
            const valid = !value.trim() || validEmailRE.test(value)

            if(valid) { 
                    msg = ''
            }

            if (field === 'salesRepEmail'){
                setSalesRepEmailErr(msg)
            } else if (field === 'email'){
                setEmailErr(msg)
            } else {
                setHCPEmailErr(msg)
            }

            props.onValidationError(msg)
        }
    }
    
    function getAllOfficeContent() {
        let officeElements = []
        let first = true
        for (let office of props.offices) {
            officeElements.push(officeContent(office.key, office, !first))
            first = false
        }

        return officeElements
    }
    
    function hcpContent(show) {
        if (show){
        return <Box
        className='demo-group-extended' 
        sx={{
            mt: '14px'
        }}
    >
        <p className='demo-section-header'>Please enter the name of the HCP whose device will be shared *</p>
        <TextField
            required
            label='Healthcare Provider Name'
            variant='outlined'
            className='text-field'
            sx={{
                width: '50%'
            }}

            value={props.hcpName}
            onChange={handleHCPField('hcpName')}
        />
        <p className='demo-section-header'>
            Please enter the preferred primary point of contact for the shared account (email to receive ECG notifications) *
            <br/>
            This will replace the existing HCP's login email
        </p>
        <TextField
            required
            label='Shared Account Email'
            variant='outlined'
            className='text-field'
            sx={{
                ml: '14px',
                width: '50%'
            }}
            error={!!hcpEmailErr}
            helperText={hcpEmailErr}
            value={props.hcpEmail}
            onChange={handleHCPField('hcpEmail')}
            onBlur={validateEmail('hcpEmail')}
        />
    </Box>      
    } else {
        return <Box
        className='demo-group' 
        sx={{
            mt: '14px'
        }}
        />
    }
}

    function officeContent(key, office, showDelete) {
        return <OfficeForm
            office={office}
            key={key}
            onOfficeChange={handleOfficeField}
            onShippingChange={handleShippingField}
            onSameAsShippingChange={handleSameAsShipping}
            onOfficeRemove={handleOnOfficeRemove}
            showDelete={showDelete}
        ></OfficeForm>
    }

    //Dispayed in register when on the demographic step
    return (
        <div>
            <Box
                display='flex'
                flexDirection='column'

            >
                <p id='demoEnterText' className='demo-text'>Enter your demographic information below so that an In-Office ECG device may be sent to you.</p>
                <p className='demo-section-header'>Name &amp; Degree</p>
                <Box
                    className='demo-group'
                >
                    <Box sx={{width: '352px'}}>
                    <FormControl sx={{width: '127px'}}>
                        <InputLabel id='demo-prefix-label'>Prefix</InputLabel>
                        <Select 
                            labelId='demo-prefix-label'
                            label='Prefix'
                            value={props.demographic.prefix}
                            onChange={handleDemoField('prefix')}
                        >
                            <MenuItem value='Dr.'>Dr.</MenuItem>
                            <MenuItem value='Mr.'>Mr.</MenuItem>
                            <MenuItem value='Ms.'>Ms.</MenuItem>
                            <MenuItem value='Mrs.'>Mrs.</MenuItem>
                            <MenuItem value='Prof.'>Prof.</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        required
                        label='First'
                        variant='outlined'
                        className='text-field'
                        sx={{
                            width: '207px',
                            ml: '14px'
                        }}
                        value={props.demographic.firstName}
                        onChange={handleDemoField('firstName')}
                    />
                    </Box>
                    <TextField
                        required
                        label='Last'
                        variant='outlined'
                        className='text-field'
                        sx={{
                            ml: '12px',
                            width: '200px',
                        }}
                        value={props.demographic.lastName}
                        onChange={handleDemoField('lastName')}
                    />
                    <TextField
                        required
                        label='Email'
                        variant='outlined'
                        className='text-field'
                        sx={{
                            ml: '14px',
                            maxWidth: '350px',
                            width: '350px'
                        }}
                        // error={!!emailErr}
                        // helperText={emailErr}
                        value={props.demographic.email}
                        onChange={handleDemoField('email')}
                        onBlur={validateEmail('email')}
                    />
                </Box>

                <Box>
                    <FormControl sx={{width: '349px'}}>
                        <InputLabel id='demo-degree-label'>Degree</InputLabel>
                        <Select 
                            labelId='demo-degree-label'
                            label='Degree'
                            value={props.demographic.degree}
                            onChange={handleDemoField('degree')}
                        >
                            <MenuItem value='MD'>MD</MenuItem>
                            <MenuItem value='DO'>DO</MenuItem>
                            <MenuItem value='NP'>NP</MenuItem>
                            <MenuItem value='PA'>PA</MenuItem>
                            <MenuItem value='APRN'>APRN</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        required
                        label='NPI #'
                        variant='outlined'
                        className='text-field'
                        inputProps={{ maxLength: 10 }}
                        sx={{
                            ml: '14px',
                            width: '200px',
                        }}
                        value={props.demographic.npi}
                        error={!!npiErr}
                        helperText={npiErr}
                        onChange={handleNPIField('npi')}
                        onBlur={handleNPIField('npi')}
                    />
                </Box>
                <p className='demo-section-header'>Sales Representative Email</p>
                <Box>
                    <TextField
                        label='Email'
                        id='email'
                        variant='outlined'
                        className='text-field'
                        sx={{
                            mb: '14px',
                            maxWidth: '350px',
                            width: '349px'
                        }}
                        error={!!salesRepEmailErr}
                        helperText={salesRepEmailErr}
                        value={props.demographic.salesRepEmail}
                        onChange={handleDemoField('salesRepEmail')}
                        onBlur={validateEmail('salesRepEmail')}
                    />
                </Box>
                <FormControl sx={{marginTop: '10px'}}>
                    <FormLabel id="demo-row-radio-buttons-group-label">AliveCor Account *</FormLabel>
                    <RadioGroup
                        required
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={props.demographic.aliveCorAccount}
                        onChange={handleDemoField('aliveCorAccount')}
                    >
                        <FormControlLabel value="newAccount" control={<Radio />} label={<p>New Account (ECG and iPad)</p>} />
                        <FormControlLabel value="existingAccount" control={<Radio />} label={<p>Existing Account (iPad Only)</p>} />
                        <FormControlLabel value="sharedAccount" control={<Radio />} label={<p>Shared Account (No ECG or iPad)</p>} />
                    </RadioGroup>
                </FormControl>
                {hcpContent(showHCPContent)}
                {getAllOfficeContent()}
                <Box
                    sx={{
                        mb: '14px'
                    }}
                >
                    <Box
                        display='flex'
                        flexDirection='row'
                    >
                        <Button onClick={props.onOfficeAdd}
                            id='newoffice'
                            variant='outlined'
                            disabled={props.disableAddMore}
                        >
                            + ADDITIONAL OFFICE
                        </Button>
                        <Box
                            sx={{
                                ml: '15px'
                            }}
                        >
                            <p className='demo-text'>If you practice at multiple locations and wish to receive a device for each location, please complete</p>
                        </Box>
                    </Box>

                </Box>
            </Box>
        </div>
    )
}

export default Demographics
