import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function VerifyEmailModal() {
    return (
        <Dialog>
            <DialogTitle>
                Verify Email
            </DialogTitle>
            Please verify your email from adobe before returning
            <Button className="filled-button">Return</Button>
        </Dialog>
    )
}

export default function AdobeSignModal(props) {
    const handleClose = () => {

    }
  return (
    <div>
      <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative", backgroundColor: "white" }}>
          <Toolbar>
            <Button className='outlined-button'
              onClick={props.handleClose}
            >
              Return
            </Button>
          </Toolbar>
        </AppBar>
        <iframe style={{ height: "100%" }} src={props.url} />
      </Dialog>
    </div>
  );
}
