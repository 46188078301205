import React from "react";
import { useState, useEffect } from "react";
import axios from "../api/axios";
import "./styles.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress"
import AdobeSignModal from "./AdobeSignModal";
import downloadSignedPDF from "../SigningUtils";

const SIGNING_STATUS_URL = "signing/status/";
const REQUEST_SIGNING_URL = "signing/request/";

function Esign({ handleBAA }) {
  const [baa, setBAA] = useState(false);

  const [showAdobeSignModal, setShowAdobeSignModal] = useState(false);
  const [adobeSignUrl, setAdobeSignUrl] = useState("");
  const [loadingSignStatus, setLoadingSignStatus] = useState(true);
  const [documentSignTitle, setDocumentSignTitle] = useState('');
  const [downloadingBAA, setDownloadingBAA] = useState(false)

  const sendSigningRequest = (docName) => {
    let config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("authToken")}`,
      },
    };

    let data = {
      docName: docName,
    };

    return axios.post(REQUEST_SIGNING_URL, data, config);
  };

  const onBAAClick = () => {
    if (baa) {
      downloadSignedPDF("attestation_baa", setDownloadingBAA)();
    } else {
      sendSigningRequest("attestation_baa")
        .then((response) => {
          const url = response.data.signingUrl;
          setAdobeSignUrl(url);
          setDocumentSignTitle('ATTESTATION & BAA')
          setShowAdobeSignModal(true);
        })
        .catch((error) => {
          console.log(error);
          alert("Something went wrong on our end. Please contact us for help.");
        })
    }
  };

  useEffect(() => {
    getSigningStatus();
  }, []);

  const getSigningStatus = () => {
    setLoadingSignStatus(true);

    let config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("authToken")}`,
      },
    };

    axios
      .get(SIGNING_STATUS_URL, config)
      .then((response) => {
        if (response.data.attestation_baa === "signed") {
          setBAA(true)
          handleBAA(true)
        } else {
          setBAA(false)
          handleBAA(false)
        }
      
      })
      .catch((error) => {
        console.log(error);
        alert("Something went wrong on our end. Please contact us for help.");
      })
      .finally(() => {
        setLoadingSignStatus(false);
      });
  };

  const onAdobeSignModalClose = () => {
    getSigningStatus();
    setShowAdobeSignModal(false);
  };

  //Displays when on the esign step in register
  return (
    <div>
      <Box display="flex" flexDirection="column">
        <AdobeSignModal
          url={adobeSignUrl}
          open={showAdobeSignModal}
          handleClose={onAdobeSignModalClose}
          title={`Sign ${documentSignTitle}`}
        />
        <Box
        display="flex"
        flexDirection="row"
          sx={{
            mb: "30px",
          }}
        >
          <p>
            Review legal documents and e-sign using AdobeSign. Copies of your signed documents will be emailed to you.
          </p>
        </Box>
        <Box display="flex" flexDirection="column">
        
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Button
              className="outlined-button"
              onClick={onBAAClick}
              disabled={loadingSignStatus || downloadingBAA}
            >
              {" "}
              ATTESTATION & BUSINESS ASSOCIATE AGREEMENT (BAA)
            </Button>

            <Box>
            {loadingSignStatus || downloadingBAA ? (
                <CircularProgress />
              ) : (
                <p className={baa ? "signed" : "not-signed"}>
                  {" "}
                  {baa ? "Your signed Attestation and BAA was successfully processed" : "Not Signed"}
                </p>
              )}
            </Box>
          </Box>
          <p>
          The Attestation Form provides rules and eligibility to participate in the program. The BAA is the document signed to protect patient
          privacy and is a document between Market Dynamics (vendor) and physician.
          </p>
        </Box>
      </Box>
    </div>
  );
}

export default Esign;
