import React from 'react'
import { useState, useRef, useEffect, useContext } from 'react'
// import AuthContext from '../context/AuthProvider'
import axios from '../api/axios'
import { useNavigate, Link } from 'react-router-dom'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import "@fontsource/montserrat";
import OfficeForm from './OfficeForm'
const LOGIN_URL = '/auth/login/'
const SIGNING_STATUS_URL = '/signing/status/'

function Login(props) {
  const nameRef = useRef();
  const errRef = useRef();

  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [success, setSuccess] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [pwResetDialogOpen, setPwResetDialogOpen] = useState('')

  let navigate = useNavigate()

  useEffect(() => {
    setErrMsg('')
  }, [name, password])

  const handleName = (e) => {
    setName(e.target.value);
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      let params = {
        username: name.toLowerCase(),
        password: password
      }
      
      const response = await axios.post(LOGIN_URL, params)
      const authToken = response.data.token
      const username = response.data.username
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('username', username)
      props.onLogin()

      setName("")
      setPassword("")
      setSuccess(true)

      let config = {
        headers: {
          "Authorization": `JWT ${localStorage.getItem('authToken')}`
        }
      }

      axios.get(SIGNING_STATUS_URL, config)
        .then((response) => {
          if (response.data.attestation_baa !== 'signed') {
            navigate("../account")
          } else {
            navigate("../loggedin")
          }
        })
        .catch((err) => {
          console.log(err)
          alert("Something went wrong, please contact us for assistance.")
        })



      // possible error messages if log in failed
    } catch (err) {
      if (!err?.response) {
        console.log(err)
        setErrMsg("No server response")
      } else if (err.response?.status === 400) {
        setErrMsg('Invalid email address or password.')
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized')
      } else {
        setErrMsg('Login Failed')
      }

    }
  }

  const handlePasswordReset = () => {
    navigate("../resetpw")
  }


  //displays log in screen
  return (


    <Box>
      <Paper className='content-paper'>
        <Box
          className='color-l-background'
          display='flex'
          flexDirection='column'
          alignItems='center'
          maxWidth='100%'
        >
          <h1 align="center" style={{ marginTop: "50px" }}>
              Welcome
          </h1>
          <p>
            Click below to begin registration
          </p>
          <Box display="flex" flexDirection="column" alignItems="center" style={{ marginTop: "30px" }}>
              <Button sx={{ width: "100px" }} className="filled-button" href="/agreement/">
                REGISTER
              </Button>
                <br/>
              <p className="having-issues" style={{ marginTop: "30px", marginBottom: "50px" }}>
                <a href="/loggedin/">Available Resources</a>
              </p>
            </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default Login



