import { nanoid } from 'nanoid'

const CreateNewOffice = () => {
  return {
      key: nanoid(),
      additional: false,
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      phoneNumber: '',
      pocName: '',
      pocPhoneNumber: '',
      pocEmail: '',
      sameAsShipping: true,
      shippingaddress: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          postalCode: '',
          phoneNumber: '',
      }
  }
}

export default CreateNewOffice