import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

function AdditionalDeviceRequested() {
  const navigate = useNavigate()

  const back = (e) => {
    e.preventDefault()
    navigate(-1)
  }

  return (
    <Paper className='content-paper' sx={{padding: '50px 170px 50px 170px;'}}>
      <h1 align='center'>Device Requested</h1>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <div>
          <p>You have successfully requested an additional ECG device.</p>
          <p>You will be receiving your ECG device in the mail within the next 1-2 business days.</p>
          <p>Tracking information for your ECG device will be emailed to you when available.</p>
        </div>
        <Button onClick={back} className='filled-button' sx={{width: '301px', marginTop: '75px'}}>RETURN TO ACCOUNT SUMMARY</Button>
      </Box>
    </Paper>
  )
}

export default AdditionalDeviceRequested