import { useState, useEffect } from 'react'
import axios from '../../api/axios'
import { Link, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress'
import OfficeForm from '../OfficeForm'
import CreateNewOffice from '../../Office'
import downloadSignedPDF from '../../SigningUtils';
import RequestDevice from '../../RequestDevice';

const SIGNED_DOC_DOWNLOAD_URL = 'signing/download/'
const OFFICE_LIMIT = 3

function LoggedIn(props) {
    const [demographic, setDemographic] = useState({})
    const [offices, setOffices] = useState([])
    const [deactivateAccountOpen, setDeactivateAccountOpen] = useState(false)
    const [showRequestAdditional, setShowRequestAdditional] = useState(false)
    const [requestDeviceLoading, setRequestDeviceLoading] = useState(false)
    const [disableAddMore, setDisableAddMore] = useState(false)
    const showDelete = false

    const [newOffices, setNewOffices] = useState([CreateNewOffice()])

    const navigate = useNavigate()


    useEffect(() => {
        setDisableAddMore(!(offices.length + newOffices.length < OFFICE_LIMIT))
      }, [offices, newOffices])
    
    const optionalDiv = (value) => {
        if(value.trim().length > 0) {
            return (
                <div>
                    {value}
                </div>
            )
        } 

        return <></>
    }

    const addressElements = (addressObj) => {
        const line3 = `${addressObj.city}, ${addressObj.state} ${addressObj.postalCode}`
        return (
            <div className='address-info'>
                {optionalDiv(addressObj.addressLine1)}
                {optionalDiv(addressObj.addressLine2)}
                {optionalDiv(line3)}
            </div>
        )
    }

    const officeAddress = () => {
        let elms = []
        for (let office of offices) {
            elms.push(addressElements(office))
        }

        return elms
    }

    const shippingAddress = () => {
        let elms = []
        for (let office of offices) {
            if (!office.sameAsShipping) {
                elms.push(addressElements(office.shippingaddress))
            } else {
                elms.push(addressElements(office))
            }
        }

        return elms
    }

    const pointOfContact = () => {
        let elms = []
        for (let office of offices) {
            elms.push((
                <div className='poc-info'>
                    {optionalDiv(office.pocName)}
                    {optionalDiv(office.pocPhoneNumber)}
                    {optionalDiv(office.pocEmail)}
                </div>
            ))
        }
        return elms
    }

    const showDeactivateAccountModal = (e) => {
        e.preventDefault()
        setDeactivateAccountOpen(true)
    }

    const onDeactivateAccountClose = (e, reason) => {
        if (!reason || reason !== 'backdropClick') {
            setDeactivateAccountOpen(false)
        }
    }

    const onRequestAdditional = () => {
        setShowRequestAdditional(true)
    }

    function handleOfficeField(office, field, value) {
        let state = [...newOffices]
        office[field] = value
        setNewOffices(state)
    }

    function handleShippingField(office, field, value) {
        let state = [...newOffices]
        office.shippingaddress[field] = value
        setNewOffices(state)
    }

    function handleSameAsShipping(office, value) {
        let state = [...newOffices]
        office.sameAsShipping = value
        console.log(office)
        setNewOffices(state)
    }

    function handleOnOfficeRemove(officeToRemove) {
        setNewOffices(newOffices.filter(office => office !== officeToRemove))
    }

    function onUpdate() {
        setRequestDeviceLoading(true)
        RequestDevice(newOffices, true).then(() => {
            navigate('/additionaldevicerequested')
            setOffices([...offices, ...newOffices])
          })
          .catch((err) => {
            console.log(err)
            alert("Something went wrong on our end. Please contact us for help.");
          })
          .finally(() => {
            setRequestDeviceLoading(false)
            setShowRequestAdditional(false)
            setNewOffices([CreateNewOffice()])
        })    
    }

    function onCancel() {
        setNewOffices([CreateNewOffice()])
        setShowRequestAdditional(false)
    }

    function onAddNewOffice() {
        let newOfficesState = [...newOffices, CreateNewOffice()]
        setNewOffices(newOfficesState)
    }

    function officeContent() {
        let elms = []
        let first = true
        for (let newOffice of newOffices) {
            elms.push(
                <OfficeForm
                    office={newOffice}
                    onOfficeChange={handleOfficeField}
                    onShippingChange={handleShippingField}
                    onSameAsShippingChange={handleSameAsShipping}
                    onOfficeRemove={handleOnOfficeRemove}
                    showDelete={!first}
                />
            )

            first = false
        }

        return elms
    }

    return (
        <Box sx={{
            width: '100%'
        }}>
            <Paper className='content-paper  logged-in-demo-info'>
                {!showRequestAdditional ? (
                    <>
                        <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                        >
                            <h1>Available Resources</h1>
                            <Box
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                                sx={{
                                    mt: '20px',
                                }}
                                >
                                    <p className="links">Click the links for more information about ZEPOSIA and the In-Office ECG Service Program</p>
                            </Box>
                            <Box
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                            >
                                <Box
                                display='flex'
                                sx={{
                                    mt: '20px',
                                }}
                                >
                                    <a href="/KardiaMobile IFU Aug 2022.pdf" className='filled-button'>INSTRUCTIONS FOR USE FOR KARDIAMOBILE 6L (AC-019)</a> 
                                </Box>  
                                <Box
                                display='flex'
                                sx={{
                                    mt: '20px',
                                }}
                                >
                                    <a href='/QuickReferenceGuide.pdf' className='filled-button'>KARDIAMOBILE QUICK REFERENCE GUIDE</a>
                                </Box>
                                <Box
                                display='flex'
                                sx={{
                                    mt: '20px',
                                }}
                                >
                                    <a href="https://packageinserts.bms.com/pi/pi_zeposia.pdf" className='filled-button'>ZEPOSIA FULL PRESCRIBING INFORMATION</a>
                                </Box>
                                <Box
                                display='flex'
                                sx={{
                                    mt: '20px',
                                }}
                                >
                                    <a href='https://www.zeposiahcp.com/ulcerative-colitis' className='filled-button'>ZEPOSIA HCP WEBSITE</a>
                                </Box>                                                   
                            </Box>
                            <Box
                                display='flex'
                                sx={{
                                    mt: '60px',
                                }}
                            >
                                <p>Questions? Call 844-333-6982</p>
                            </Box>
                            
                        </Box>
                    </>
                ) : (
                    <>
                        {officeContent()}
                        {requestDeviceLoading ? <CircularProgress /> : (
                        <Box display='flex' flexDirection='row' columnGap='20px'>
                            <Button className='outlined-button' onClick={onAddNewOffice} disabled={disableAddMore}>+ ADDITIONAL OFFICE</Button>
                            <Button className='filled-button' onClick={onUpdate}>Update</Button>
                            <Button className='filled-button' onClick={onCancel}>Cancel</Button>
                        </Box>
                        )}
                    </>
                )}
            </Paper>
        </Box>
    )
}

export default LoggedIn