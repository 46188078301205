import React from 'react'
import { useState, useRef, useEffect, useContext } from 'react'
import axios from '../api/axios'
import { useNavigate, Link } from 'react-router-dom'
import "./styles.css"
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Logo from "../Logo.png"

function Program() {

    let navigate = useNavigate()

    const handleInOffice = () => {
        navigate("../account/")
    }

    //displays 2 possible programs for physicians
    return (
        <Box>
            <Paper className='program content-paper'>
                <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                    alignItems='center'
                >

                    <Box
                        sx={{
                            mt: '55px',
                            mb: '45px'
                        }}
                    >
                        <p className='program-select'>Please select a program to continue.</p>
                    </Box>

                    <Box
                        sx={{
                            mb: '45px'
                        }}
                    >
                        <Button
                            className='programb1'
                            variant='contained'
                            onClick={handleInOffice}
                        >

                            IN-OFFICE ECG SERVICE PROGRAM
                        </Button>
                    </Box>

                    <Button
                        className='programb2'
                        variant='contained'
                    >
                        OVERREAD SERVICE PROGRAM
                    </Button>
                </Box>

            </Paper>
        </Box>
    )
}

export default Program