import React from 'react'
import { useState, useRef, useEffect, useContext } from 'react'
import axios from '../api/axios'
import { useNavigate, Link } from 'react-router-dom'
import "./styles.css"
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import CircularProgress from '@mui/material/CircularProgress'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { nanoid } from 'nanoid'
import Demographics from './Demographics'
import Esign from "./Esign"
import CreateNewOffice from '../Office'
import CreateNewHCP from '../HCP'

const OFFICE_LIMIT = 3

function BlueCircleStepIcon(props) {
  return (
    <CircleCheckedFilled style={{ color: "rgb(25, 118, 210)"}}/>
  )
}

function Register() {
  let navigate = useNavigate()

  const [accepted, setAccepted] = useState(false)
  const [attest, setAttest] = useState(false)
  const [baa, setBAA] = useState(false)
  const [activeStep, setActiveStep] = useState(0);
  const [demographic, setDemographic] = useState({
    'prefix': '',
    'firstName': '',
    'lastName': '',
    'email': '',
    'degree': '',
    'salesRepEmail' : '',
    'aliveCorAccount' : '',
  })
  const [hcp, setHCP] = useState({
    'hcpName': '',
    'hcpEmail': '',
  })
  const [offices, setOffices] = useState([CreateNewOffice()])
  const [disableAddMore, setDisableAddMore] = useState(false)
  const [formErrMsg, setFormErrMsg] = useState('')

  useEffect(() => {
    setDisableAddMore(!(offices.length < OFFICE_LIMIT))
  }, [offices])

  const handleHCPChange = (field, value) => {
    let state = { ...hcp }
    state[field] = value
    setHCP(state)
  }

  const handleDemographicChange = (field, value) => {
    let state = { ...demographic }
    state[field] = value
    setDemographic(state)
  }

  const handleOfficeChange = (office, field, value) => {
    let state = [...offices]
    office[field] = value
    setOffices(state)
  }

  const handleShippingChange = (office, field, value) => {
    let state = [...offices]
    office.shippingaddress[field] = value
    setOffices(state)
  }

  const handleSameAsShipping = (office, value) => {
    let state = [...offices]
    office.sameAsShipping = value
    setOffices(state)
  }

  const handleValidationError = (errMsg) => {
    setFormErrMsg(errMsg)
  }
 
  const addHCP = () => {
    setHCP(CreateNewHCP())
  }
  const removeHCP = () => {
    setHCP()
  }

  const addOffice = () => {
    if(offices.length < OFFICE_LIMIT) {
      let newOffice = {
        'key': nanoid(),
        'addressLine1': '',
        'addressLine2': '',
        'city': '',
        'state': '',
        'postalCode': '',
        'phoneNumber': '',
        'pocName': '',
        'pocPhoneNumber': '',
        'pocEmail': '',
        'sameAsShipping': true,
        'shippingaddress': {
          'addressLine1': '',
          'addressLine2': '',
          'city': '',
          'state': '',
          'postalCode': '',
          'phoneNumber': '',
        }
      }

      setOffices([...offices, newOffice])

    } 
  }

  const removeOffice = (officeToRemove) => {
    setOffices(offices.filter(office => office !== officeToRemove))
    
    if(offices.length < OFFICE_LIMIT - 1) {
      setDisableAddMore(false)
    }
  }

  const handleAttest = (value) => {
    setAttest(value)
  }

  const handleBAA = React.useCallback((value) => {
    setBAA(value)
  })

  const handleNext = (e) => {
    e.preventDefault()
    if (activeStep == 0) {
      let params = {
        username: demographic.email.toLowerCase(),
        password: '123Test@',
        email: demographic.email.toLowerCase()
      }
      axios.post('/user/create/', params)
      .then((response) => {
        axios.post('/auth/login/', params)
        .then((response) => {
          localStorage.setItem('authToken', response.data.token);
          localStorage.setItem('username', response.data.username)
          saveDemographic(demographic)
          saveOffices()
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
          
      })
        .catch((error) => {
          alert("Something went wrong, please contact us for assistance.")
      })  
    })
    .catch((error) => {
      alert("Something went wrong, please contact us for assistance.")
    })
  }
  if (activeStep === steps.length - 1) {
    setAccepted(true)
  }
  };


  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleResources = () => {
    navigate("../loggedin/")
  }

  const handleReset = () => {
    setActiveStep(0);
  };

  function getSteps() {
    return [
      {
        label: "Demographic Information",
        icon: BlueCircleStepIcon

      }, 
      {
        label: "E-Sign Documents",
        icon: null
      }
    ]
  }



  function getStepsContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Demographics
            demographic={demographic}
            onDemoChange={handleDemographicChange}
            onHCPAdd={addHCP}
            onHCPHide={removeHCP}
            onHCPChange={handleHCPChange}
            offices={offices}
            onOfficeAdd={addOffice}
            onOfficeRemove={removeOffice}
            onOfficeChange={handleOfficeChange}
            onShippingChange={handleShippingChange}
            onSameAsShippingChange={handleSameAsShipping}
            disableAddMore={disableAddMore}
            onValidationError={handleValidationError}
          >
          </Demographics>
        )
      case 1:
        return <Esign handleAttest={handleAttest}
          handleBAA={handleBAA}

        > </Esign>;
      default: return "Unknown Step"
    }
  }

  const steps = getSteps();

  const saveDemographic = (demographic) => {
    let config = {
      headers: {
        'Authorization': `JWT ${localStorage.getItem('authToken')}`
      }
    }

    axios.post('/user/demographic/', demographic, config)
      .then((response) => {

      })
      .catch((error) => {
        alert("Something went wrong, please contact us for assistance.")
      })
  }

  const saveOffices = () => {
    let config = {
      headers: {
        'Authorization': `JWT ${localStorage.getItem('authToken')}`
      }
    }
    axios.post('/user/office/', offices, config)
      .then((response) => {

      })
      .catch((error) => {
        alert("Something went wrong, please contact us for assistance.")
      })
  }

  //displays stepper for demographics and esign
  if (!accepted) {
    return (
      <Box id='registerBox'>
        <Paper className='content-paper '>
          <Box
            display='flex'
            flexDirection='column'
          >
            <form onSubmit={handleNext}>
              <Box
                sx={{
                  mt: '10px',
                  ml: "20px"
                }}
              >
                <Stepper
                  orientation="vertical"
                  activeStep={activeStep}
                >
                  {steps.map(step => (
                    <Step key={step.label}>
                      <StepLabel StepIconComponent={step.icon}>
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        {getStepsContent(activeStep)}
                        {activeStep === steps.length ? 'The Steps Complete' : (
                          <>
                        <Box
                          className="save-back-button-group"
                        >
                        
                          <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Box
                              sx={{
                                mr: "20px"
                              }}
                            >
                              <Button
                                disabled={(activeStep === steps.length - 1) && (baa === false) || !!formErrMsg}
                                className="filled-button"
                                type="submit"
                              >
                                CONTINUE
                              </Button>
                            </Box>
                            <Box>
                              <a onClick={handleBack}
                                disabled={activeStep === 0}
                                className='link'
                              >
                                BACK
                              </a>
                            </Box>
                          </div>
                          
                          {activeStep === steps.length - 1 ? (
                            <Box
                              sx={{
                                ml: "290px"
                              }}
                            >
                              Questions?
                              <Link to="/contact" className='link'>CONTACT US</Link>
                            </Box>
                          ) : (<></>)}
                        </Box>
                        <div className='errMsg' style={{height: '20px'}}>{formErrMsg}</div>
                        </>
                      )}
                      </StepContent>
                    </Step>
                  ))}

                </Stepper>
              </Box>
              
            </form>
          </Box>
        </Paper>
      </Box>

    )
  } else {
    //returns congratulations screen
    return (
      <Box>
        <Paper className='content-paper '>
          <Box
            className='finbox'
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            alignItems='center'
            sx={
              {
                maxWidth: "400px",
                padding: "40px 140px 40px 140px"
              }
            }
          >
            <h1>Registation Complete</h1>
            <p>You have completed the registration process for the ZEPOSIA® (ozanimod) In-Office ECG Service Program.</p>
            <p>You will be receiving your ECG device in the mail and your AliveCor Account Activation email within the next 1-2 business days.</p>
            <p>Tracking information for your ECG device will be emailed to you when available.</p>
            <Button className="filled-button" href='/loggedin'>Available Resources</Button> 
          </Box>
        </Paper>
      </Box>
    )
  }
}

export default Register