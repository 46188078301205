import { Link, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

function Contact() {
  const navigate = useNavigate()

  const back = (e) => {
    e.preventDefault()
    navigate(-1)
  }

  return (
    <Paper className='content-paper setup-device'>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <h1>Contact Us</h1>
        <Box display='flex' flexDirection='column' alignItems='center' sx={{ mt: '10px' }}>
          <p className='contact-us'>844-333-6982</p>
          <p className='contact-us'>MDx-ZEPSupport@iqvia.com</p>
        </Box>
        <Box sx={{ mt: '30px' }}>
          <a onClick={back} className='link'>BACK</a>
        </Box>
      </Box>
    </Paper>
  )
}

export default Contact