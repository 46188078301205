import { Link, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

function SetupDevice() {
  const navigate = useNavigate()

  const back = (e) => {
    e.preventDefault()
    navigate(-1)
  }

  return (
    <Paper className='content-paper setup-device'>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <h1>How to Setup Devices</h1>
        <Box display='flex' flexDirection='row' columnGap='40px' marginTop='20px' className='links'>
          <a href='/QuickReferenceGuide.pdf' className='filled-button'>Quick Reference Guide</a>
        </Box>
        <Box sx={{mt: '30px'}}>
        <a onClick={back} className='link'>BACK</a>
        </Box>
      </Box>
    </Paper>
  )
}

export default SetupDevice