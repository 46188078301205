import React from 'react'
import { useState } from 'react'
import axios from '../api/axios'
import { useNavigate, Link } from 'react-router-dom'
import "./styles.css"
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';



function Create() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passConf, setPassConf] = useState('')
    let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    let emailAddressRE = new RegExp('.+@.+')
    const [passMsg, setPassMsg] = useState('')
    const REGISTER_URL = '/user/create/'
    const LOGIN_URL = '/auth/login/'
    const MINIMUM_PW_LENGTH = 8

    let navigate = useNavigate()


    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handlePassword = (e) => {
        setPassword(e.target.value);
    }

    const handlePassConf = (e) => {
        setPassConf(e.target.value);
    }

    const validateCreateUserForm = () => {
        if (!emailAddressRE.test(email)) {
            setPassMsg('A valid email address is required.')
        } else if (!strongPassword.test(password) || password.length < MINIMUM_PW_LENGTH) {
            setPassMsg('Password does not meet requirements.')
        } else if (password !== passConf) {
            setPassMsg('Passwords do not match.')
        } else {
            return true
        }

        return false
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validateCreateUserForm()) {
            setPassMsg("")
            try {

                let params = {
                    username: email.toLowerCase(),
                    password: password,
                    email: email,
                }


                const response = await axios.post(REGISTER_URL, params)

                let params2 = {
                    username: email.toLowerCase(),
                    password: password
                }
                const response2 = await axios.post(LOGIN_URL, params2)
                const authToken = response2.data.token

                localStorage.setItem('authToken', authToken);

                navigate("../verify", {state: {email: email}})

            } catch (err) {
                if (!err?.response) {
                    setPassMsg("No server response.")
                } else if (err.response?.status === 400) {
                    setPassMsg('Missing Username or Password.')
                } else if (err.response?.status === 401) {
                    setPassMsg('Unauthorized.')
                } else if (err.response.status === 409) {
                    setPassMsg('Account already exists.')
                } else {
                    setPassMsg('Login Failed.')
                }
            }
        }
    }



    // This displays the user creation if a conformation code has not been sent yet
    return (
        <Box>
            <p className='registration'> Registration</p>
            <form>
                <Box>
                    <Paper className='content-paper'>
                        <Box
                            className='register-paper'
                            display='inline-flex'
                            flexDirection='column'
                            alignItems='center'
                        >

                            <Box
                                className='enter-margin'
                            >
                                <p className='enter-email'> Enter email and create a password</p>
                            </Box>

                            <Box
                                display='flex'
                                flexDirection='row'
                                className='fields-margin'
                                mb='0'
                            >

                                <Box
                                    display='inline-flex'
                                    flexDirection='column'
                                    alignItems='center'
                                >
                                    <div>
                                        <Box
                                            className='email-margins'
                                        >
                                            <TextField onChange={handleEmail}
                                                value={email}
                                                type="email"
                                                id="email"
                                                label='Email'
                                                required
                                            />
                                        </Box>
                                    </div>


                                    <div>
                                        <Box
                                            className='email-margins'
                                        >
                                            <TextField onChange={handlePassword}
                                                value={password}
                                                type="password"
                                                id="password"
                                                label='Password'
                                                required
                                            />
                                        </Box>
                                    </div>


                                    <div>
                                        <Box
                                            className='email-margins'
                                        >
                                            <TextField onChange={handlePassConf}
                                                value={passConf}
                                                type="password"
                                                id="passConf"
                                                label='Re-enter Password'
                                                required
                                            />
                                        </Box>
                                    </div>
                                </Box>

                                <Box
                                    display='inline-flex'
                                    flexDirection='column'
                                    className='constraint-box'
                                >
                                    <p className='constraint'>Password must contain:</p>
                                    <p className='constraint'>-One Uppercase character</p>
                                    <p className='constraint'>-One lowercase character</p>
                                    <p className='constraint'>-One number(0-9)</p>
                                    <p className='constraint'>-One special character</p>
                                    <p className='constraint'>-Minimum length of {MINIMUM_PW_LENGTH} characters</p>
                                </Box>

                            </Box>
                            <div className='passMsg'>{passMsg}</div>
                            <Button
                                type='submit'
                                className='filled-button'
                                onClick={handleSubmit}
                            >
                                Continue
                            </Button>
                        </Box>
                    </Paper>
                </Box>
            </form>
        </Box>
    )
}

export default Create