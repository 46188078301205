import DeleteForverIcon from '@material-ui/icons/DeleteForever';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function OfficeForm(props) {
  let office = props.office
  let showDelete = props.showDelete

  function handleOfficeField(office, field) {
    return function (e) {
      props.onOfficeChange(office, field, e.target.value)
    }
  }

  function handleShippingField(office, field) {
    return function (e) {
      props.onShippingChange(office, field, e.target.value)
    }
  }

  function handleSameAsShipping(office) {
    return function (e) {
      props.onSameAsShippingChange(office, e.target.checked)
    }
  }

  const handleRemoveOffice = (e) => {
    props.onOfficeRemove(office)
  }

  return (
    <div>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
      >
      </Box>

      <Box
        className='address-group'
      >
        <Box
          id="officeAddressColumn"
          display='flex'
          flexDirection='column'
        >
          <span className='demo-section-header'>
            Office Address
            {showDelete ? <DeleteForverIcon
              onClick={handleRemoveOffice}
              style={{ cursor: 'pointer', fontSize: 20, verticalAlign: 'bottom' }}
            />
              : <></>
            }
          </span>

          <Box
            sx={{
              mb: '14px'
            }}>
            <TextField
              required
              label='Address Line 1'
              variant='outlined'
              className='address-input text-field'
              value={office.addressLine1}
              onChange={handleOfficeField(office, 'addressLine1')}
            />
          </Box>

          <Box
            sx={{
              mb: '14px'
            }}
          >
            <TextField
              label='Address Line 2'
              variant='outlined'
              className='address-input text-field'
              value={office.addressLine2}
              onChange={handleOfficeField(office, 'addressLine2')}
            />
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            sx={{
              mb: '14px'
            }}
          >
            <TextField
              required
              label='City'
              variant='outlined'
              className='text-field'
              value={office.city}
              onChange={handleOfficeField(office, 'city')}
            />

            <TextField
              required
              label='State'
              variant='outlined'
              sx={{
                ml: '14px'
              }}
              className='text-field'
              value={office.state}
              onChange={handleOfficeField(office, 'state')}
            />
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            sx={{
              mb: '20px'
            }}
          >
            <TextField
              required
              label='Postal Code'
              variant='outlined'
              className='text-field'
              value={office.postalCode}
              onChange={handleOfficeField(office, 'postalCode')}
            />

            <TextField
              required
              label='Phone'
              variant='outlined'
              sx={{
                ml: '14px'
              }}
              className='text-field'
              value={office.phoneNumber}
              onChange={handleOfficeField(office, 'phoneNumber')}
            />
          </Box>

        </Box>

        <Box
          id='shippingAddressColumn'
          display='flex'
          flexDirection='column'
          sx={{
            paddingTop: '0px;'
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                style={{
                  color: "#0032A0",
                }}
                checked={office.sameAsShipping}
                onChange={handleSameAsShipping(office)}>
              </Checkbox>

            }
            label={<span className='shipping-address-text'>Same as Shipping Address</span>} />

          {office.sameAsShipping ? ('') :
            <span>
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='flex-end'
              >
              </Box>

              <Box
                sx={{
                  mb: '14px'
                }}>
                <TextField
                  required
                  label='Shipping Address Line 1'
                  variant='outlined'
                  className='address-input text-field'
                  value={office.shippingaddress.addressLine1}
                  onChange={handleShippingField(office, 'addressLine1')}
                />
              </Box>

              <Box
                sx={{
                  mb: '14px'
                }}
              >
                <TextField
                  label='Shipping Address Line 2'
                  variant='outlined'
                  className='address-input text-field'
                  value={office.shippingaddress.addressLine2}
                  onChange={handleShippingField(office, 'addressLine2')}
                />
              </Box>

              <Box
                display='flex'
                flexDirection='row'
                sx={{
                  mb: '14px'
                }}
              >
                <TextField
                  required
                  label='Shipping City'
                  variant='outlined'
                  className='text-field'
                  value={office.shippingaddress.city}
                  onChange={handleShippingField(office, 'city')}
                />

                <TextField
                  required
                  label='Shipping State'
                  variant='outlined'
                  sx={{
                    ml: '14px'
                  }}
                  className='text-field'
                  value={office.shippingaddress.state}
                  onChange={handleShippingField(office, 'state')}
                />
              </Box>

              <Box
                display='flex'
                flexDirection='row'
                sx={{
                  mb: '20px'
                }}
              >
                <TextField
                  required
                  label='Shipping Postal Code'
                  variant='outlined'
                  className='text-field'
                  value={office.shippingaddress.postalCode}
                  onChange={handleShippingField(office, 'postalCode')}
                />

                <TextField
                  required
                  label='Shipping Phone'
                  variant='outlined'
                  sx={{
                    ml: '14px'
                  }}
                  className='text-field'
                  value={office.shippingaddress.phoneNumber}
                  onChange={handleShippingField(office, 'phoneNumber')}
                />
              </Box>
            </span>
          }
        </Box>
      </Box>
      <p className='demo-section-header'>Office Point of Contact</p>
      <Box
        className='poc-group'
      >
        <TextField
          label='Name'
          variant='outlined'
          className='text-field'
          value={office.pocName}
          onChange={handleOfficeField(office, 'pocName')}
        />

        <TextField
          label='Phone Number'
          variant='outlined'
          className='text-field'
          value={office.pocPhoneNumber}
          onChange={handleOfficeField(office, 'pocPhoneNumber')}
        />

        <TextField
          label='Email'
          variant='outlined'
          className='text-field'
          value={office.pocEmail}
          onChange={handleOfficeField(office, 'pocEmail')}
        />
      </Box>
    </div>
  )
}

export default OfficeForm