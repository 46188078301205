import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Logo from "./Logo.png";
import Register from "./components/Register";
import Agreement from "./components/Agreement";
import Create from "./components/Create";
import LoggedIn from "./components/LoggedIn/LoggedIn";
import Program from "./components/Program";
import Contact from "./components/LoggedIn/Contact";
import SetupDevice from "./components/LoggedIn/SetupDevice";
import AdditionalDeviceRequested from './components/AdditionalDeviceRequested';
import "./App.css";

function PrivateRoute(props) {
  return props.children;
}

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  const onLogin = () => {
    setLoggedIn(true);
  };


  return (
    <BrowserRouter>
      <Box className="overall">
        <Box
          className="rectangle overall-container"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            className="outer-header"
            sx={{
              display: "inline-flex",
              justifyContent: "center",
              alignContent: "flex-end",
            }}
          >
            <p className="this-site-is-intende">THIS SITE IS INTENDED FOR U.S. HCP AUDIENCES ONLY</p>
          </Box>
          <Box className="header">
            <img src={Logo} className="logo" />
            <p className="zeposia-ozanimod">ZEPOSIA® (ozanimod) In-Office ECG Service Program</p>
          </Box>
          <Box className="page-content">
            <Routes>
              <Route path="/" element={<Login onLogin={onLogin} />} />
              <Route path="/login/" element={<Login onLogin={onLogin} />} />
              <Route path="/agreement/" element={<Agreement />} />
              <Route path="/register/" element={<Create />} />
              <Route
                path="/loggedin/"
                element={
                  <PrivateRoute>
                    {" "}
                    <LoggedIn />{" "}
                  </PrivateRoute>
                }
              />
              <Route
                path="/account/"
                element={
                  <PrivateRoute>
                    {" "}
                    <Register />{" "}
                  </PrivateRoute>
                }
              />
              <Route
                path="/program/"
                element={
                  <PrivateRoute>
                    {" "}
                    <Program />{" "}
                  </PrivateRoute>
                }
              />
              <Route
                path="/setupdevice/"
                element={
                  <PrivateRoute>
                    {" "}
                    <SetupDevice />{" "}
                  </PrivateRoute>
                }
              />
              <Route
                path="/contact/"
                element={
                  <PrivateRoute>
                    {" "}
                    <Contact />{" "}
                  </PrivateRoute>
                }
              />
              <Route
                path="/additionaldevicerequested/"
                element={
                  <PrivateRoute>
                    {" "}
                    <AdditionalDeviceRequested />{" "}
                  </PrivateRoute>
                }
              />
            </Routes>
          </Box>
          <Grid id="grid" container spacing={2} >
            <Grid container item xs={6} direction="column" >
              <Box id="trademarks" display="flex" flexDirection="column">
                <img id="bms-logo" src="/bms-logo.png" />
                <p>ZEPOSIA, ZEPOSIA 360 Support and ZEPOSIA logo are trademarks of Celgene</p>
                <p>Corporation, a Bristol Myers Squibb company.</p>
                <p>All other trademarks are the property of their respective owners.</p>
                <p>© 2024 Bristol-Myers Squibb Company. 2084-US-2301439 01/24</p>
              </Box>  
            </Grid>
            <Grid container item xs={6} direction="column" >
              <Box id="legal">
                <p><a href="https://www.bms.com/privacy-policy.html">Privacy Notice</a></p>
                <p><a href="https://www.bms.com/legal-notice.html">Legal Notice</a></p>
              </Box>
            </Grid>
          </Grid>        
          <Box id="prescribing-info">
                <p>Please see the <a href="https://packageinserts.bms.com/pi/pi_zeposia.pdf">full Prescribing Information</a></p>
          </Box>
        </Box> 
      </Box>
    </BrowserRouter>
  );
}

export default App;
