import React from "react";
import { useState, useRef, useEffect, useContext } from "react";
import axios from "../api/axios";
import { useNavigate, Link } from "react-router-dom";
import "./styles.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Logo from "../Logo.png";

function Agreement() {
  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <p className="eligibility">Eligibility</p>
      </Box>

      <Box>
        <Paper className="agreement-paper content-paper">
          <Box
            sx={{
              padding: "20px 40px 20px 40px",
            }}
          >
            <h1 align="center" style={{ marginBottom: "40px" }}>
              ZEPOSIA® (ozanimod) In-Office ECG Service Program Attestation
            </h1>
            <h1>In-Office ECG Service Program</h1>
            <p>
              Prior to initiation of treatment with ZEPOSIA® , patients are evaluated for preexisting conduction
              abnormalities. This program (the “Program”) is designed to equip prescribing healthcare providers with ECG
              equipment and support for technical interpretation necessary for cardiac evaluation of a patient prior to
              initiating treatment with ZEPOSIA® . In patients with certain preexisting conditions, advice from a
              cardiologist should be sought— see Warnings and Precautions in Prescribing Information.
            </p>
            <br></br>
            <h1>Participant Attestation</h1>
            <p>
              Participation in the Program requires that the Provider participant read, understand and follow basic
              terms and conditions that govern such participation. These terms are as follows:
            </p>
            <p>
              <ol type="A" className="agreement-terms-list">
                <li>I currently do not have ECG equipment available.</li>
                <li>
                  I will use the ECG equipment (consisting of an ECG monitor and paired tablet) provided by Bristol
                  Myers Squibb (“BMS”) for the sole purposes of monitoring or evaluating adult patients for potential
                  treatment with ZEPOSIA® for moderately to severely active ulcerative colitis (UC), and for those
                  patients to have the option to review and electronically sign the ZEPOSIA 360 Support Program Patient
                  Authorization and Agreement Form.
                </li>
                <li>
                  Neither I, nor anyone in my office or practice, will submit any bill or request for payment to any
                  patient, insurer, government program, or other payer for the administration or interpretation of this
                  test.
                </li>
                <li>
                  I will not utilize the ECG equipment to monitor or evaluate any patient participating in any state or
                  federal healthcare program, including Medicaid, Medicare, Medigap, CHAMPUS, TriCare, Veterans Affairs
                  (VA), or Department of Defense (DoD), or for residents of Rhode Island.
                </li>
                <li>
                  I will be responsible for the ECG equipment and will return it to BMS or its designee upon completion
                  of my participation in the Program, termination of the Program, or at the request of BMS.
                </li>
                <li>
                  I understand that Market Dynamics, acting as the Program Administrator, and BMS may conduct reasonable
                  monitoring to ensure that Program rules, terms, and conditions are being adhered to, without BMS or
                  Market Dynamics obtaining access to any identifiable patient health information.
                </li>
                <li>
                  I understand that Bristol Myers Squibb reserves the right to rescind, revoke, or amend this Program at
                  any time without notice.
                </li>
              </ol>
            </p>

            <h1 style={{ height: "auto" }}>
              By signing below, I certify that I have read and understand the conditions of participation in this
              Program and agree to abide by all of the terms set forth herein.
            </h1>
            <Box display="flex" flexDirection="column" alignItems="center" style={{ marginTop: "30px" }}>
              <Button sx={{ width: "100px" }} className="filled-button" href="/account/">
                AGREE
              </Button>

              <p className="having-issues">
                Having issues? Call <span className="phone-number">844-333-6982</span> for help
              </p>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default Agreement;